// Imports => Moment
import dayjs from 'dayjs';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import { AcSaveState, AcGetState } from '@utils';

export const getLocale = () => {
	return (
		AcGetState(KEYS.LOCALE) ||
		window.navigator.userLanguage ||
		window.navigator.language ||
		'nl-NL'
	);
};

export const setLocale = (_locale) => {
	const locale = _locale || getLocale();

	dayjs.locale(locale);
	AcSaveState(KEYS.LOCALE, locale);
};

export default {
	getLocale,
	setLocale,
};
