// Imports => Constants
import { TITLES } from './titles.constants';

// Imports => Utilities
import { AcUUID } from '@utils/ac-uuid';

// Imports => Views
import {
	AcAccountSelect,
	AcAchievement,
	AcActionDetail,
	AcActionParticipants,
	AcForgotPassword,
	AcCanvas,
	AcConversation,
	AcConversationFinished,
	AcFullscreenComment,
	AcHangInThere,
	AcHome,
	AcLogin,
	AcPearl,
	AcResetPassword,
	AcSettings,
	AcScoreFAQ,
	AcTipsAndTricks,
} from '@views';

export const PATHS = {
	HOME: '/',
	LOGIN: '/inloggen',
	FORGOT_PASSWORD: '/code-vergeten',
	// RESET_PASSWORD: '/code-resetten',
	RESET_PASSWORD: '/reset-password',
	ACCOUNT_SELECT: '/account',
	ACTION_DETAIL: '/actie/:id',
	ACTION_PARTICIPANTS: '/actie/:id/deelnemers',
	CANVAS: '/canvassen/:id',
	TIPS_AND_TRICKS: '/tips-en-tricks',
	SETTINGS: '/instellingen',
	CONVERSATION: '/in-gesprek/:id?',
	CONVERSATION_FINISHED: '/gesprek-afgerond/:id?',
	HANG_IN_THERE: '/hou-vol/:id?',
	ACHIEVEMENT: '/badge/:id?',
	COMMENT: '/opmerking-toevoegen/:id?',
	SCORE_FAQ: '/bitterballen-score',
	PEARL: '/pareltje/:id?',
	NOT_FOUND: '/404',
};

export const ROUTES = {
	HOME: {
		id: AcUUID(),
		name: 'Home',
		label: TITLES.HOME,
		path: PATHS.HOME,
		component: AcHome,
		roles: null,
		forbidden: true,
		exact: true,
	},
	FORGOT_PASSWORD: {
		id: AcUUID(),
		name: 'Forgot Password',
		label: null,
		path: PATHS.FORGOT_PASSWORD,
		component: AcForgotPassword,
		roles: null,
		forbidden: false,
		exact: true,
	},
	RESET_PASSWORD: {
		id: AcUUID(),
		name: 'Reset Password',
		label: null,
		path: PATHS.RESET_PASSWORD,
		component: AcResetPassword,
		roles: null,
		forbidden: false,
		exact: true,
	},
	LOGIN: {
		id: AcUUID(),
		name: 'Login',
		label: null,
		path: PATHS.LOGIN,
		component: AcLogin,
		roles: null,
		forbidden: false,
		exact: true,
	},
	ACCOUNT_SELECT: {
		id: AcUUID(),
		name: 'Account Select',
		label: null,
		path: PATHS.ACCOUNT_SELECT,
		component: AcAccountSelect,
		roles: null,
		forbidden: false,
		exact: true,
	},
	ACTION_DETAIL: {
		id: AcUUID(),
		name: 'Action Detail',
		label: null,
		path: PATHS.ACTION_DETAIL,
		component: AcActionDetail,
		roles: null,
		forbidden: true,
		exact: true,
	},
	ACTION_PARTICIPANTS: {
		id: AcUUID(),
		name: 'Action Participants',
		label: null,
		path: PATHS.ACTION_PARTICIPANTS,
		component: AcActionParticipants,
		roles: null,
		forbidden: true,
		exact: true,
	},
	CONVERSATION: {
		id: AcUUID(),
		name: 'Conversation',
		label: null,
		path: PATHS.CONVERSATION,
		component: AcConversation,
		roles: null,
		forbidden: true,
		exact: true,
	},
	CONVERSATION_FINISHED: {
		id: AcUUID(),
		name: 'Conversation Finished',
		label: null,
		path: PATHS.CONVERSATION_FINISHED,
		component: AcConversationFinished,
		roles: null,
		forbidden: true,
		exact: true,
	},
	CANVAS: {
		id: AcUUID(),
		name: 'Canvas',
		label: null,
		path: PATHS.CANVAS,
		component: AcCanvas,
		roles: null,
		forbidden: true,
		exact: true,
	},
	TIPS_AND_TRICKS: {
		id: AcUUID(),
		name: 'Canvas',
		label: null,
		path: PATHS.TIPS_AND_TRICKS,
		component: AcTipsAndTricks,
		roles: null,
		forbidden: true,
		exact: true,
	},
	SETTINGS: {
		id: AcUUID(),
		name: 'Settings',
		label: null,
		path: PATHS.SETTINGS,
		component: AcSettings,
		roles: null,
		forbidden: true,
		exact: true,
	},
	HANG_IN_THERE: {
		id: AcUUID(),
		name: 'Hang in there',
		label: null,
		path: PATHS.HANG_IN_THERE,
		component: AcHangInThere,
		roles: null,
		forbidden: true,
		exact: true,
	},
	ACHIEVEMENT: {
		id: AcUUID(),
		name: 'Achievement',
		label: null,
		path: PATHS.ACHIEVEMENT,
		component: AcAchievement,
		roles: null,
		forbidden: true,
		exact: true,
	},
	COMMENT: {
		id: AcUUID(),
		name: 'Fullscreen Comment',
		label: null,
		path: PATHS.COMMENT,
		component: AcFullscreenComment,
		roles: null,
		forbidden: true,
		exact: true,
	},
	SCORE_FAQ: {
		id: AcUUID(),
		name: 'Score FAQ',
		label: null,
		path: PATHS.SCORE_FAQ,
		component: AcScoreFAQ,
		roles: null,
		forbidden: true,
		exact: true,
	},
	PEARL: {
		id: AcUUID(),
		name: 'Pearl',
		label: null,
		path: PATHS.PEARL,
		component: AcPearl,
		roles: null,
		forbidden: true,
		exact: true,
	},
};

export const AUTHENTICATION_ROUTES = [
	ROUTES.LOGIN,
	ROUTES.ACCOUNT_SELECT,
	ROUTES.FORGOT_PASSWORD,
	ROUTES.RESET_PASSWORD,
];

export const NAVIGATION_ITEMS = [];

export const DEFAULT_ROUTE = ROUTES.HOME;
export const REDIRECT_ROUTE = ROUTES.LOGIN;
