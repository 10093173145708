// Imports => React
import React, { useEffect, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => SCSS
import '@styles/index.scss';

// Imports => Constants
import {
	DEFAULT_ROUTE,
	KEYS,
	PATHS,
	REDIRECT_ROUTE,
	ROUTES,
	TITLES,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcSupportsWEBP } from '@utils';

// Imports => Molecules
const AcToasterHoc = loadable(() =>
	import('@molecules/ac-toaster-hoc/ac-toaster-hoc.web')
);
const AcModal = loadable(() => import('@molecules/ac-modal/ac-modal.web'));
const AcNotConnectedModal = loadable(() =>
	import('@molecules/ac-not-connected-modal/ac-not-connected-modal.web')
);
const AcLocationRequiredModal = loadable(() =>
	import('@molecules/ac-location-required-modal/ac-location-required-modal.web')
);

// Imports => Atoms
const AcErrorBoundary = loadable(() =>
	import('@atoms/ac-error-boundary/ac-error-boundary.web')
);
const AcPrivateRoute = loadable(() =>
	import('@atoms/ac-private-route/ac-private-route.web')
);
const AcSplashScreen = loadable(() =>
	import('@views/ac-splash-screen/ac-splash-screen.web')
);
const AcScrollHOC = loadable(() => import('@atoms/ac-scroll-hoc/ac-scroll-hoc.web'));

const _CLASSES = {
	ROOT: 'ac-root',
	MAIN: 'ac-app',
	ROUTE: {
		SECTION: 'ac-route__section',
	},
};

const App = ({
	store,
	store: {
		profile,
		auth: { loaded, is_authorized },
		campaigns,
		navigator,
		ui,
		current_sw_instance,
	},
}) => {
	const location = useLocation();

	useEffect(() => {
		handleRouteChanged();
	}, [location]);

	useEffect(() => {
		if (!navigator.is_connected) displayNotConnectedModal();
		else {
			store.ui.reset(KEYS.MODAL);
		}
	}, [navigator.is_connected]);

	useEffect(() => {
		setTimeout(() => {
			if (!navigator.has_permission) displayLocationRequiredModal();
			else {
				store.ui.reset(KEYS.MODAL);
			}
		}, 500);
	}, [navigator.has_permission]);

	useEffect(() => {
		if (current_sw_instance) {
			const instanceWaiting = current_sw_instance.waiting;

			if (instanceWaiting) {
				instanceWaiting.postMessage({ type: 'SKIP_WAITING' });

				instanceWaiting.addEventListener('statechange', (event) => {
					if (event.target.state === 'activated') {
						window.location.reload(true);
					}
				});
			}
		}
	}, [current_sw_instance]);

	const locationModalCallback = () => {
		if (navigator.has_permission) {
			store.ui.reset(KEYS.MODAL);
		}
	};

	const displayLocationRequiredModal = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		await store.ui.reset(KEYS.MODAL);
		await store.ui.set(KEYS.MODAL, {
			title: TITLES.LOCATION_REQUIRED,
			body: <AcLocationRequiredModal callback={locationModalCallback} />,
			offset: true,
			closeable: false,
			visible: true,
			actions: [],
			callback: () => {
				store.ui.reset(KEYS.MODAL);
			},
		});
	};

	const displayNotConnectedModal = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		await store.ui.reset(KEYS.MODAL);
		await store.ui.set(KEYS.MODAL, {
			title: null,
			body: <AcNotConnectedModal />,
			offset: true,
			closeable: false,
			visible: true,
			actions: [],
			callback: () => {
				store.ui.reset(KEYS.MODAL);
			},
		});
	};

	const handleRouteChanged = (event) => {
		ui.setValue(KEYS.NAVIGATION, KEYS.VISIBLE, false);
		ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);

		if (is_authorized) {
			profile.who_am_i();
			campaigns.list();
		}
	};

	const getRouteSectionClassNames = useMemo(() => {
		return clsx(_CLASSES.ROUTE.SECTION);
	}, []);
	const renderDefaultRoute = useMemo(() => {
		if (!is_authorized) {
			return (
				<Route path={'*'} element={<Navigate to={REDIRECT_ROUTE.path} replace />} />
			);
		}

		const route = DEFAULT_ROUTE;

		return (
			<Route
				key={`default-route-${route.id}`}
				path={'/'}
				element={
					<AcPrivateRoute
						name={route.name}
						path={route.path}
						component={route.component}
						forbidden={route.forbidden}
						authorized={is_authorized}
					/>
				}
			/>
		);
	}, [is_authorized]);

	const renderRoutes = useMemo(() => {
		const collection = ROUTES;
		let result = [];
		let key;

		for (key in collection) {
			const item = collection[key];
			const { forbidden, allowed, locale } = item;

			if (forbidden && !is_authorized) continue;
			if (AcIsSet(locale) && locale !== profile.current_language) continue;
			else {
				const object = (
					<Route
						key={`route-${item.id}`}
						path={item.path}
						element={
							<AcPrivateRoute
								name={item.name}
								path={item.path}
								component={item.component}
								forbidden={item.forbidden}
								authorized={is_authorized}
							/>
						}
					/>
				);
				result.push(object);
			}
		}

		return result;
	}, [is_authorized, location, profile.current_language]);

	const renderNotFoundRoute = useMemo(() => {
		if (!is_authorized) return null;

		return <Route path={'*'} element={<Navigate to={PATHS.NOT_FOUND} replace />} />;
	}, [is_authorized]);

	const renderModal = useMemo(() => {
		return <AcModal {...store.ui.modal}>{store.ui.modal.body}</AcModal>;
	}, [store.ui.modal, store.ui.modal.closeable, store.ui.modal.visible]);

	return (
		<AcErrorBoundary screen={location.pathname}>
			<AcScrollHOC>
				<main className={AcSupportsWEBP() ? 'ac-supports-webp' : ''}>
					<section id={KEYS.SCROLLER} className={getRouteSectionClassNames}>
						<ReactCSSTransitionReplace
							transitionName='fade-wait'
							transitionEnterTimeout={300}
							transitionLeaveTimeout={200}
						>
							<div key={location.key}>
								<Routes location={location}>
									{renderRoutes}
									{renderDefaultRoute}
									{renderNotFoundRoute}
								</Routes>
							</div>
						</ReactCSSTransitionReplace>
					</section>

					{renderModal}

					<AcToasterHoc
						queue={store.toasters.queue}
						callback={store.toasters.remove}
					/>

					{!is_authorized && <AcSplashScreen />}
				</main>
			</AcScrollHOC>
		</AcErrorBoundary>
	);
};

export default withStore(observer(App));
